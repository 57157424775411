<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="order" :query="{  }">
      
    </Mural>

    <main class="lesson-content q-my-lg">
      <div  class="content-table row flex q-pt-xs">
        <div class="column col-12">
            <Table
              class="full-height"
              :list="order"
              :columns="columnsTable"              
              :addRemove="false"
              :addEdit="false"
              :addView="false"
          />
        </div>
      </div>

    </main>
    <q-inner-loading
        :showing="loadingPage"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
    />
  </div>

</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import WareHouseService from '@/services/WareHouseService';
import Table from "@/components/shared/tables/TableDynamic.vue";
import {ref, onMounted} from "vue";
import { useQuasar } from "quasar";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "Order",
  components: {
    Mural,
    DefaultBtn,
    Table,  
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Pedido: "]);
    let order = ref([]);
    let $q = new useQuasar();
    let router = new useRouter();
    let route = new useRoute();
    let loadingPage = ref(false);

    let orderId = ref(null);
    let columnsTable = ref([
      {name: 'order_id', align: 'left', label: 'ID', field: 'order_id', sortable: false},
      {name: 'name_product', align: 'left', label: 'Item', field: 'name_product', sortable: false},
      {name: 'date_sale', align: 'left', label: 'Data da Solicitação', field: 'date_sale', sortable: false},
      {name: 'total', align: 'left', label: 'Valor do Item', field: 'total', sortable: false},
      {name: 'description_status', align: 'left', label: 'Status', field: 'description_status', sortable: false},
    ]);

    async function _getOrders(){
      orderId.value = route.query.orderId;
      if(orderId.value == null){
        router.push({ name: "order"});
      }

      breadcrumbs.value = ["Pedido: " + orderId.value];
      loadingPage.value = true;
      let data = await _wareHouseService.getOrder(orderId.value);
      order.value = data.data;
      loadingPage.value = false;
    }

   

    onMounted(() => {
      _getOrders();
    });

    return {
      breadcrumbs,
      order,
      loadingPage,
      columnsTable
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}
.content-card {
  Width: 100%;
  Height: 206px;
  background: #F7F7F7;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.content {

  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: #9C9C9C;

  .bold_number {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000 !important;
  }
}

.image-groups {
  border-radius: 20px;
}

.card-btn {
  box-shadow: 3px 10px 15px #00000033;
  width: 100px;
}



</style>